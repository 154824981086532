<template>
  <div>
    <div class="d-flex justify-content-between">
      <span class="floor_name">{{ $t('floor.self') }} {{ floor.floor_number }}</span>
      <span class="floor_name">
        <a
          class="btn btn-outline-secondary"
          :href="subMerchantPath()"
        >
          {{ $t('sub_merchant.edit') }}
        </a>
      </span>
    </div>
    <div class="grid-container row">
      <RowBuildingUnit
        v-for="(buildingUnit, index) in floor.building_units"
        :key="index"
        :building-unit="buildingUnit"
      />
    </div>
  </div>
</template>

<script>
import RowBuildingUnit from './rowBuildingUnit.vue';
export default {
  components: {
    RowBuildingUnit
  },
  props: {
    floor: {
      type: Object,
      required: true
    }
  },
  methods: {
    subMerchantPath: function() {
      return this.floor.sub_merchant ? `/sub_merchants/${this.floor.sub_merchant.id}/edit` :
      `/sub_merchants/new?subscribable_type=Floor&subscribable_id=${this.floor.id}`;
    }
  }
};
</script>

<style lang="scss" scoped>

.grid-container {
  padding: 1px;
}

.grid-container > div {
  border: 1px solid #2196F3;
  background-color: white;
  padding: 1px 0;
  font-size: 1rem;
  height: 150px;
}

.floor_name {
  font-size: 2rem;
}

</style>
